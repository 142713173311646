/* sundram css */

.confirm-box__actions {
	display: flex;
	text-align: center;

	justify-content: center !important;
	padding: 3rem 0 0.5rem 0;
}

.confirm-box__content {
	text-align: center;
}

.confirm-box__content span {
	text-align: center;
	color: black !important;
	text-transform: capitalize;
	font-size: 1.5rem;
}

.confirm-box__actions button:first-child {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	padding: 0.375rem 0.75rem;
}

.confirm-box__actions button:first-child:hover {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
}

.confirm-box__actions button {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	padding: 0.375rem 0.75rem;
}

.confirm-box__actions button:hover {
	color: #fff;
	background-color: #c82333;
	border-color: #bd2130;
}

.confirm-box {
	z-index: 1000;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.confirm-box__content {
	box-shadow: 0 10px 15px 0px rgb(0 0 0 / 20%);
	padding: 2em;
	width: 400px;
}
.confirm-box__overlay {
	display: none;
}

.videoTable {
	float: right;
}
.inline {
	display: inline-block;
}
.red_flag {
	background: rgba(255, 0, 0, 0.16);
	padding: 2px 5px;
	display: initial;
	text-align: center;
	border-radius: 5px;
	color: rgb(255, 0, 0);
	font-size: 10px;
}

.green_flag {
	background: rgba(0, 255, 0, 0.2);
	padding: 2px 5px;
	text-align: center;
	border-radius: 5px;
	color: rgb(11, 156, 49);
	font-size: 10px;
	display: initial;
}

.yellow_flag {
	background: rgba(250, 247, 237, 0.765);
	padding: 2px 5px;
	display: initial;
	text-align: center;
	border-radius: 5px;
	color: rgb(240, 183, 36);
	font-size: 10px;
}
.contents {
	display: contents;
}

tr:hover {
	background: rgba(0, 0, 0, 0.01);
	transition: 0.3s all;
}

.dropdown-item:hover .fe {
	color: black;
}

.publvdos {
	display: inline-block;
	padding: 0.6rem;
	margin: 0 !important;
}
.unpublvdos {
	display: inline-block;
	padding: 0.6rem;
	margin: 0 !important;
}

.react-tabs__tab {
	padding: 10px 12px;
}
.react-tabs__tab--selected {
	background: #593cc1;
	border-color: transparent;
	color: white;
	border-radius: 5px 5px 0 0;
	font-weight: bold;
}
.react-tabs__tab-list {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.PhoneInputInput {
	border: none;
}
